.register-form h1 {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-control {
  border: 1px solid #ccc;
  margin: 0;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
}

.input-group > div {
  flex: 1; /* Each child will take up an equal amount of space */
}
/* 
#confirm-password-half-width {
  flex: 0.5;
} */

.form-control:hover,
.form-control:focus {
  border-color: #4f46e5;
}

button[type="submit"] {
  width: 100%;
  padding: 1rem 0;
  /* background-color: #4f46e5;
  color: white; */
  background-color: rgb(19, 19, 19);
  color: var(--container-color);
  border: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin-top: 2rem; /* Some space above the submit button */
}

button[type="submit"]:hover {
  opacity: 0.8;
  transition: all 0.2s ease-out;
}

.brand-title-registration {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 0 0 1rem 0;
  margin-bottom: 2rem;
}

.error-message,
.success-message {
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 4px;
}

.error-message {
  color: red;
  background-color: rgba(255, 0, 0, 0.1);
}

.success-message {
  color: green;
  background-color: rgba(0, 255, 0, 0.1);
}
/* ////////////////// */

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--body-color);
  margin: 0;
}

.register-form {
  max-width: 1143px;
  width: 100%;
  min-height: 80vh;
  background-color: var(--container-color);
  padding: 3rem 4rem;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: auto;
}

.register-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 1;
}

.login-prompt {
  align-self: flex-start;
  margin-top: auto;
  text-decoration: none;
  color: #555;
  font-size: 1rem;
}

.login-text {
  color: rgb(25, 115, 206);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-text:hover {
  color: #3730a3;
}

.register-logo {
  margin-left: -13px;
  max-height: 70px;
  width: auto;
}
.input-fields {
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  gap: 10px;
  flex-shrink: 1;
}

.input-group {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
}

button[type="submit"] {
  margin-top: 2rem;
}

@media screen and (max-width: 1148px) {
  .register-form {
    height: 100vh;
  }
  .brand-text {
    font-size: 2rem;
  }
}

/* Responsive design: */
@media screen and (max-width: 768px) {
  .register-form {
    flex-direction: column;
    min-height: 100vh; /* Replace height with min-height */
    padding: 2rem;
    overflow-y: auto; /* Scroll if content overflows */

    gap: 25px;

    /* max-height: 90vh;  */
  }

  .input-fields {
    flex-direction: column;
  }

  .input-group {
    flex-direction: column;
  }
}
