.section__title {
    font-size: var(--h1-font-size);
    color: black;
    text-align: center;
}

.section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 1rem;
    text-align: center;
}

.updates-image {
    display: block;
    margin: auto auto auto auto;
    text-align: center;
    max-width: 23%;
    height: auto;
}

.grid {
    display: grid;
    gap: 1.5rem;
}

.whats-new__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.whats-new__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 1rem;
    text-align: left;
    max-width: 600px;
    max-width: 600px;
    margin: 0 auto;
    width: 566px;
    transition: transform 0.3s, box-shadow 0.3s;
}

    .whats-new__content:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

.whats-new__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-75);
    color: var(--title-color-dark);
}

.whats-new__date {
    display: block;
    font-size: var(--small-font-size);
    color: rgb(35, 106, 193);
    margin-bottom: var(--mb-0-75);
}

.whats-new__description {
    font-size: var(--small-font-size);
    color: var(--text-color);
}

.toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color-dark);
}

.feature-span {
    text-align: center;
    font-size: var(--small-font-size);
    margin-bottom: 2rem;
    color: var(--title-color-dark);
}

.fade-in {
    animation: fadeIn 0.5s forwards;
}

.fade-out {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@media screen and (max-width: 960px) {
    .whats-new__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }
}

@media screen and (max-width: 576px) {
    .whats-new__container {
        padding: 2rem;
    }

    .whats-new__content {
        width: 100%;
    }
}
