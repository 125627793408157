@keyframes blob-animate {
  0%,
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
}

@keyframes blob-animate-alternate {
  0%,
  100% {
    border-radius: 50% 50% 40% 60% / 40% 60% 30% 70%;
  }
  50% {
    border-radius: 40% 60% 50% 50% / 60% 40% 60% 40%;
  }
}

/* Base Blob Styling */
.blob {
  position: absolute;
  width: 250px;
  height: 250px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgba(0, 0, 0, 0.2);
  animation: blob-animate 15s ease-in-out infinite; /* ⬅️ Slower animation */
}

/* Unique Positions */
.blob-1 {
  top: 10%;
  left: 5%;
  animation-duration: 18s;
}

.blob-2 {
  bottom: 15%;
  right: 10%;
  animation: blob-animate-alternate 22s ease-in-out infinite; /* ⬅️ Slower movement */
}

.blob-3 {
  top: 50%;
  left: 60%;
  animation-duration: 25s;
}

.blob-4 {
  bottom: 20%;
  left: 40%;
  animation: blob-animate-alternate 30s ease-in-out infinite; /* ⬅️ Slowest */
}
