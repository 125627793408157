.result-label {
  font-weight: bold;
  font-size: 2rem;
}

.result-value {
  font-weight: bold;
  font-size: 2rem;
  margin-left: 3px;
}

.result-item {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 20px; /* Add padding */
}

.result-text {
  display: flex;
  align-items: center;
  margin-right: 40px; /* Add some space between text and SVG */
  width: 400px;
}

.img {
  width: 100px;
}
.btn-resultSession {
  padding: 8px 20px;
  border-radius: 4px;
  color: #ffffff;
  background-color: rgb(19, 19, 19);
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .result-label {
    font-size: 1.5rem;
  }
  .result-value {
    font-size: 1.5rem;
  }
  .result-item {
    padding: 30px;
  }
  .result-text {
    margin-right: 50px;
    width: 250px;
  }
}
