/* ServicesContainer styles */
.services-container {
  margin: 0;
  height: auto;
  padding: 50px 0 160px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-p {
  list-style: none; /* Removes default bullet points */
  padding-left: 0; /* Removes default padding */
}

.services-p li {
  /* Styling for each list item */
  margin-bottom: 10px; /* Adds space between items */
  padding-left: 20px; /* Space for custom bullet or indentation */
  position: relative; /* For positioning custom bullets */
}

.services-p li:before {
  content: "•"; /* Custom bullet */
  color: black; /* Bullet color */
  position: absolute;
  left: 0;
}

@media screen and (max-width: 768px) {
  .services-container {
    height: 1100px;
  }
}

@media screen and (max-width: 480px) {
  .services-container {
    height: 1300px;
  }
}

/* ServicesWrapper styles */
.services-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;
}

@media screen and (max-width: 1000px) {
  .services-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .services-wrapper {
    grid-template-columns: 1fr;
  }

  .services-container {
    padding: 40px 0;
    height: auto;
  }
}

/* ServicesCard styles */
.services-card {
  background-color: var(--container-color);
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  min-height: 325px;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  text-align: center;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  transition: all 0.2s ease-in-out;
}

.card-image {
  /* Styles for the image container */
  flex: 1; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text {
  /* Styles for the text container */
  margin-top: 0px;
  flex: 2; /* Adjust as needed */
  padding-left: 20px; /* Creates some space between the image and the text */
  text-align: left;
}

.services-card:hover {
  transform: scale(1.02);
  cursor: pointer;
}

/* ServicesIcon styles */
.services-icon {
  height: 140px;
  width: 140px;
  margin-bottom: 40px;
}

/* ServicesH1 styles */
.services-h1 {
  margin-top: 20px;
  font-size: 2.5rem;
  color: black;
  margin-bottom: 80px;
}

/* ServicesH2 styles */
.services-h2 {
  font-size: 1.8rem;
  color: black;
}

/* ServicesP styles */
.services-p {
  font-size: 1rem;
  padding-top: 20px;
  line-height: 1.5;
  color: black;
}

@media screen and (max-width: 650px) {
  .services-container {
    padding: 30px 0; /* Adjust container padding for smaller screens */
  }

  .services-h1 {
    font-size: 1.5rem; /* Adjust heading font size for smaller screens */
  }

  .services-card {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    padding: 20px; /* Adjust card padding for smaller screens */
  }

  .services-card {
    /* Existing styles... */
    display: flex;
    flex-direction: column; /* Ensure flex direction is column */
    align-items: center; /* Center-align items for a neater look */
  }

  .card-image,
  .card-text {
    width: 100%; /* Ensure full width for child elements */
    /* Additional styling as needed */
    text-align: center;
  }
  .card-image {
    margin-bottom: 50px; /* Adjust space between image and text */
  }

  .services-icon {
    width: 80px; /* Adjust icon size for smaller screens */
    height: 80px;
  }

  .services-h2 {
    font-size: 1.2rem; /* Adjust subheading font size */
  }

  .services-p {
    font-size: 0.9rem; /* Adjust paragraph font size */
  }
}

/* For small devices */
@media screen and (max-width: 600px) {
  .card-text ul,
  .card-text .services-p {
    text-align: left;
  }

  .services-icon {
    height: 140px;
    width: 140px;
    margin-bottom: 10px;
  }

  .card-image {
    margin-bottom: 0px; /* Adjust space between image and text */
  }

  .services-card {
    padding: 50px; /* Further reduce card padding for very small screens */
  }

  .services-icon {
    width: 80px; /* Further reduce icon size for very small screens */
    height: 80px;
  }

  .services-h2 {
    font-size: 1.2rem; /* Further reduce subheading font size */
  }

  .services-p {
    font-size: 0.9rem; /* Further reduce paragraph font size */
  }
}

@media screen and (max-width: 450px) {
  .card-text ul,
  .card-text .services-p {
    text-align: left;
  }

  .card-text {
    padding: 0;
  }
}
