.button {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
}

.subscribe-button {
    display: inline-block;
    background-color: white;
    color: black;
    padding: 10px 24px;
    border-radius: 2px;
    font-weight: var(--font-medium);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.button:hover {
    background-color: var(--title-color-dark);
}

.button__icon {
    margin-left: var(--mb-0-5);
}

.button--flex {
    display: inline-flex;
    align-items: center;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

.section {
    padding: 6rem 0 2rem;
}

.section__title {
    font-size: var(--h1-font-size);
    color: black;
}

.section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
    text-align: center;
}

.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.grid {
    display: grid;
    gap: 1.5rem;
}

.contact__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
    justify-content: center;
}

.contact__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

.contact__card-icon {
    font-size: 2rem;
    color: black;
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data {
    font-size: var(--small-font-size);
}

.contact__card-title {
    font-weight: var(--font-medium);
}

.contact__card-title {
    color: white;
    font-size: 0.2em;
}

.contact__card-data {
    display: block;
    margin-bottom: var(--mb-0-75);
    color: black;
}

.contact__button {
    color: black;
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover .contact__button-icon {
    transform: translate(0.25rem);
}

.contact__form {
    width: 360px;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.lottie-animation {
    display: flex;
    justify-content: center; /* Centers the animation horizontally */
    align-items: center; /* Centers the animation vertically if needed */
    margin: 0 auto 10px auto; /* Adds top and bottom margin */
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area {
    height: 11rem;
}

    .contact__form-area textarea {
        resize: none;
    }

.contact__maintenance-message {
    font-style: italic;
    color: #666; /* Match the color with the other grey text in the file */
    margin-top: 20px;
}

.contact__maintenance-email {
    color: #666;
    text-decoration: none;
}

    .contact__maintenance-email:hover {
        text-decoration: underline;
    }

/*=============== BREAKPOINTS ===============*/
/* For large devices */

/* For medium devices */
@media screen and (max-width: 960px) {
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .contact__info {
        justify-content: center;
    }

    .contact__form {
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
        padding: 2rem;
    }

    .contact__info {
        grid-template-columns: 1fr;
    }

    .contact__form {
        width: 100%;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
}
