.dashboard-container {
  text-align: center;
  padding: 50px 20px 100px 20px;
  background-color: rgb(249, 249, 249);
  position: relative;
}

.image-slider {
  position: relative;
  display: inline-block; /* Makes the container as wide as its content */
}

.dashboard-container button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #333;
}

.dashboard-container button:focus {
  outline: none;
}

.prev-button {
  left: 400px; /* Adjust as needed */
}

.next-button {
  right: 400px; /* Adjust as needed */
}

.dashboard-title {
  font-size: 2.5rem;
  color: #333; /* Adjust the color as needed */
}

.dashboard-subtitle {
  font-size: 1.8rem;
  margin-bottom: 80px;
  color: #555; /* Adjust the color as needed */
}

.dashboard-image {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
  opacity: 0; /* Set initial opacity to 0 */
  animation: fadeIn 0.5s ease-in-out forwards; /* Animation for fade-in */
}

/* DashboardSection.css */

.image-slider {
  position: relative;
  display: inline-block; /* Keeps the container as wide as its content */
}

.dashboard-image {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.peaking-owl {
  position: absolute;
  top: -68px; /* Initial position */
  left: 80%; /* Initial horizontal position */
  transform: translateX(-50%); /* Adjust horizontal position */
  width: 100px; /* Initial size */
  height: auto; /* Maintain aspect ratio */
  z-index: 10; /* Ensure it's above the image */
}

.lottie-animation {
  width: 925px;
  height: 200;
}

@media screen and (max-width: 2700px) {
  .peaking-owl {
    left: 69%; /* Adjust the horizontal position for medium screens */
    width: 80px; /* Adjust the size */
    top: -59px; /* Adjust the vertical position */
  }

  /* Other adjustments for buttons, text, etc. */
}

@media screen and (max-width: 1500px) {
  .peaking-owl {
    left: 80%; /* Adjust the horizontal position for medium screens */
    width: 80px; /* Adjust the size */
    top: -59px; /* Adjust the vertical position */
  }

  .dashboard-container {
    text-align: center;
    padding: 50px 20px 100px 20px;
    background-color: rgb(249, 249, 249);
    position: relative;
    /* Adjust the container size or padding */
  }

  .dashboard-title,
  .dashboard-subtitle {
    /* Adjust font size if necessary */
  }

  .dashboard-image {
    /* Adjust the size of the image */
    max-width: 80%;
    width: 100%;
    height: auto;
  }

  /* Other adjustments for buttons, text, etc. */
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .peaking-owl {
    left: 80%; /* Adjust the horizontal position for medium screens */
    width: 80px; /* Adjust the size */
    top: -59px; /* Adjust the vertical position */
  }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .peaking-owl {
    left: 80%; /* Adjust the horizontal position for small screens */
    width: 50px; /* Adjust the size */
    top: -35px; /* Adjust the vertical position */
  }

  .dashboard-image {
    max-width: 80%;
  }

  .lottie-animation {
    width: 90%;
    height: 200;
  }
}

@media screen and (max-width: 480px) {
  .peaking-owl {
    left: 80%; /* Adjust the horizontal position for very small screens */
    width: 40px; /* Adjust the size */
    top: -30px; /* Adjust the vertical position */
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Rest of your CSS */

.dashboard-release {
  font-size: 2rem;
  color: #666; /* Adjust the color as needed */
}
