.feature {
  padding: 160px 0 20px 0;
  position: relative;
}

.feature-bx {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  text-align: center;
  padding: 35px 20px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
}
.specific-university {
  margin-bottom: 30px;
  text-align: center;
}

.feature h5 {
  font-size: 25px;
  font-weight: 700;
}

.feature-slider {
  width: 90vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.feature-slider .item img {
  width: 30%;
  margin: 0 auto 15px auto;
}

/* Adjust the positioning for the left arrow */
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 2px; /* adjust this value as necessary */
  position: absolute;
  /* replace with your desired color */
  background: rgb(222, 222, 222);

  /* replace with your desired background color */
}

/* Adjust the positioning for the right arrow */
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 2px; /* adjust this value as necessary */
  position: absolute;
  color: black; /* replace with your desired color */
  background: rgb(
    222,
    222,
    222
  ); /* replace with your desired background color */
}

.react-multiple-carousel__arrow {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; /* Optional: for a smooth hover effect */
  z-index: 999;
}
