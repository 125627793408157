/* Similar structure to CardSection.css with adjustments for testimonials */

.testimonial-container {
  margin: 0;
  height: auto;
  padding: 50px 0 160px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 50px;
}

.testimonial-card {
  width: 100%; /* Ensure width is 100% instead of fixed 400px */
  max-width: 400px; /* Set a maximum width if needed */
  box-sizing: border-box; /* Ensure padding is included in the width */
  background-color: var(--container-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 680px;
  padding: 70px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  text-align: center;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
  margin-top: 100px;
  /* Adjust styles to fit testimonial format */
}

.testimonial-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

.testimonial-navigation button {
  margin: 0 10px; /* Space between buttons */
  cursor: pointer;
  background-color: white;
  font-size: 1.5rem;
  color: black;
  /* Add more styling for the buttons here */
}

.testimonial-university {
  font-style: italic;
  color: #666;
  margin-top: 15px; /* Adjust as needed */
}

.testimonial-year {
  font-style: italic;
  color: #666;
}

.testimonial-h1 {
  margin-top: 20px;
  font-size: 2.5rem;
  color: black;
  margin-bottom: 0px;

  /* Styles for the main header */
}

.testimonial-text {
  text-align: left; /* Aligns text to the left */
  padding: 15px; /* Adds padding around the text for better spacing */
}

.testimonial-h2 {
  color: royalblue; /* Sets the color of the name to blue */
  font-size: 4rem; /* Increases the font size for the name */
  position: relative;
  z-index: 1;
}

.testimonial-h2::before {
  content: "";
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 180px;
  background-image: url("../../assets/img/speech.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.says {
  font-size: 4rem;
  margin-bottom: 30px;
  margin-top: -20px;
}

.testimonial-p {
  color: black; /* Sets the color of the text to black */
  font-size: 20px; /* Sets a larger font size for the text */
  margin: 5px 0; /* Adds spacing above and below the paragraph */
}

.owl-speech-container {
  /* Positioning and sizing as needed */
  text-align: center;
}

.owl-speech-bubble {
  width: 700px; /* Smaller size for smaller screens */
  height: 700px;
  margin-top: -100px;
}

.fade-in {
  animation: fadeIn 0.5s;
}

.fade-out {
  animation: fadeOut 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .testimonial-card {
    min-height: 380px; /* Adjust the height for smaller screens */
    width: 90%; /* Make width responsive */
    padding: 70px;
  }

  .testimonial-h2 {
    font-size: 1.7rem; /* Smaller font size for the name */
  }

  .says {
    font-size: 1.7rem; /* Adjust text size */
  }

  .testimonial-p {
    font-size: 1.2rem; /* Adjust text size */
  }

  .says {
    margin-top: -10px;
  }

  .testimonial-h2::before {
    content: "";
    position: absolute;
    top: 5%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-image: url("../../assets/img/speech.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}

@media (max-width: 568px) {
  .testimonial-h1 {
    margin-top: 20px;
    font-size: 2rem;
    color: black;
    margin-bottom: 0px;

    /* Styles for the main header */
  }
}

@media (max-width: 400px) {
  .testimonial-h2::before {
    content: "";
    position: absolute;
    top: 5%;
    left: 14%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-image: url("../../assets/img/speech.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}
