.home__hero-section {
  /* padding was 160px before */
  padding: 160px 0;
  height: auto;
}

.home__hero-section.isFirst {
  display: flex;
  padding: 50px 0 0 0;
}

/* Special
  styles
  for
  the
  first
  section
  only */
.home__hero-section.isFirst .container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.home__hero-section.isFirst .home__hero-row {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.home__hero-section.isFirst .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home__hero-section.isFirst .home__hero-text-wrapper,
.home__hero-section.isFirst .home__hero-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: none;
}

.home__hero-subtitle.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home__hero-section.isFirst .home__hero-subtitle {
  max-width: 100%;
}

/*  */

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 0;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.home__title-container {
  display: flex;
  align-items: center;
}

.heading {
  margin-bottom: 24px;
  font-size: 4.4rem;
  line-height: 1.1;
  font-weight: 700;
}

.sub-heading {
  font-size: 3rem; /* Adjust the size as needed */
  line-height: 1.2;
  color: rgb(28, 28 green, 28 blue);
  margin-bottom: 24px;
  /* Add more styling as needed */
}

.highlight-your {
  color: rgb(28, 120, 201); /* Example red color for 'Your' */
}

.highlight-our {
  color: rgb(28, 120, 201); /* Example blue color for 'Our' */
}

.sub-heading.dark {
}

/* Style for the Lottie animation */
.home__owl-animation {
  width: 82px; /* or whatever width you want for the animation */
  height: 82px; /* or whatever height you want for the animation */
}

.home__hero-subtitle {
  max-width: 480px;
  margin-bottom: var(--mb-3);
  font-size: 18px;
  line-height: 1.5;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-section.isFirst .home__hero-img-wrapper {
  /* if you want to change back to og position, get rid of everything except max-width */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.home__hero-img {
  /* max-width before was 95% */

  /* max-width: 90%; */
  width: 700px;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

.home__hero-section.isFirst .home__hero-img {
  /* filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2)); */
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 1022px) {
  .home__hero-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home__hero-subtitle {
    text-align: center;
  }

  .heading {
    margin-bottom: 24px;
    font-size: 3.5rem;
    line-height: 1.1;
    font-weight: 700;
    text-align: center;
  }

  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

/* For small devices */
@media screen and (max-width: 500px) {
  .home__hero-img {
    min-width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .sub-heading {
    font-size: 2.5rem; /* Slightly smaller font size */
    line-height: 1.2; /* Adjust line height if necessary */
    text-align: center;
  }
}

/* Adjustments for smaller devices (large phones) */
@media screen and (max-width: 600px) {
  .sub-heading {
    font-size: 2rem; /* Further reduce the font size */
  }
}

/* Adjustments for very small devices (small phones) */
@media screen and (max-width: 480px) {
  .sub-heading {
    font-size: 1.8rem; /* Even smaller font size */
    line-height: 1.3; /* Adjust line height for readability */
  }
}
