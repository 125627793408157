.privacy-container {
  padding: 6em;
  margin: auto;
  max-width: 800px; /* Adjust width as needed */
  line-height: 1.6; /* Improve readability */
  font-size: 1rem;
}

.privacy-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.privacy-container h2 {
  margin-top: 20px;
  color: #333; /* Adjust color as needed */
}

.privacy-container p {
  text-align: justify;
  margin-bottom: 10px;
}

.privacy-container ul,
.privacy-container ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

.privacy-container li {
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .privacy-container {
    padding: 1em;
    font-size: 0.9rem;
    margin-top: 100px;
  }
}

@media screen and (max-width: 480px) {
  .privacy-container {
    padding: 1rem;
    font-size: 0.8rem;
  }
}
