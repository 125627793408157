/* Ensure the scroll component is centered */
.home__scroll {
  left: 50%;
  top: 50%; /* Centers vertically */

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  user-select: none;
  pointer-events: none;
}

/* Adjust the mouse SVG */
.home__scroll-mouse {
  width: 40px;
  height: 30px;
  stroke-width: 2px;
}

/* ✅ Properly Animate the Wheel */
.wheel {
  animation: wheelMove 1.5s infinite ease-in-out;
}

@keyframes wheelMove {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(60px); /* 🔥 Increased movement */
  }
  100% {
    transform: translateY(0);
  }
}

/* ✅ Make the arrow bounce */
.home__scroll-arrow {
  font-size: 20px;
  margin-top: 5px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}

/* ✅ Style the "Scroll Down" text */
.home__scroll-name {
  font-size: 14px;
  margin-top: 8px;
  color: var(--title-color);
  font-weight: bold;
}
