/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&family=Cabin:wght@400;500;600;700&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  /* rgb(9, 9, 9) */
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Cabin", sans-serif;

  /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*========== Font weight ==========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*=============== BASE ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

.option-border-style {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 72px;
  box-sizing: border-box;
  padding-top: 2px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.correct-answer {
  border-color: #4caf50;
  background-color: #e8f5e9;
}

.incorrect-answer {
  border-color: #ff5252;
  background-color: #ffebee;
}
