/* Styles for the comments section to match the DashboardQuestions component */

.comments-section {
    padding: 0 48px; /* Match the padding used in the questions component */
    margin-top: 16px;
    margin-bottom: 8px;
}

    .comments-section h4 {
        font-size: 18px;
        color: rgb(45, 50, 62); /* Match the color used for question text */
        margin-bottom: 16px;
        font-weight: 300; /* Consistent font weight */
        border-bottom: 2px solid rgba(222, 226, 230, 0.5); /* Add a subtle line underneath */
        padding-bottom: 8px; /* Add some space between text and line */
    }

textarea {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

    textarea:focus {
        border-color: #4caf50; /* Match the green focus color */
        outline: none;
    }

button {
    background-color: rgb(33, 104, 202);
    border: none;
    color: white;
    padding: 8px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 4px;
}

    button:hover {
        background-color: #dddddd;
    }

.comment {
    padding: 8px 16px;
    margin-top: 8px;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 14px;
    color: rgb(45, 50, 62);
}

    .comment p {
        margin: 4px 0;
    }

    .comment strong {
        font-weight: bold;
        color: #2d323e;
    }

.reply-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: rgb(33, 104, 202);
    font-size: 14px;
    cursor: pointer;
    margin-top: 4px;
}

    .reply-button:hover {
        text-decoration: underline;
    }

.reply-icon {
    margin-right: 4px;
}

.replies {
    border-left: 2px solid rgba(222, 226, 230, 0.5); /* Only a left line for replies */
    margin-left: 16px; /* Add some spacing */
    padding-left: 8px; /* Padding inside the replies section */
    background-color: transparent; /* Remove any background color */
}

.comment-timestamp {
    display: block;
    font-size: 12px;
    color: #888; /* Light grey color to distinguish timestamps */
    margin-top: 4px;
    margin-bottom: 8px;
}

.like-dislike-buttons {
    display: flex;
    gap: 0px;
    margin-top: 8px;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: rgb(33, 104, 202);
    font-size: 14px;
}

.liked {
    color: #4caf50; /* Green for like */
}

.disliked {
    color: #f44336; /* Red for dislike */
}
/* Specific style for the "Post" button */
.post-button {
    background-color: rgb(33, 104, 202); /* Blue background */
    color: white; /* White text */
    border: none;
    padding: 8px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition-duration: 0.4s;
}

    .post-button:hover {
        background-color: #1c61b0; /* Darker blue on hover */
    }

.firework-animation {
    position: absolute;
    top: -20px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
}
