.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--body-color);
}

.forgot-password-form {
  max-width: 400px;
  width: 100%;
  background-color: var(--container-color);
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  padding: 4rem;
}

.forgot-brand-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.forgot-brand-content {
  display: flex;
  align-items: center; /* Vertically aligns the contents */
  justify-content: center; /* Horizontally centers the contents */
  text-decoration: none; /* Removes underline from the link */
  color: inherit;
}

.forgot-brand-logo {
  max-height: 70px;
  width: auto;
}

.forgot-brand-text {
  vertical-align: middle;
}

.forgot-input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.forgot-input-group label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.forgot-input-field {
  border: 1px solid #ccc;
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.forgot-input-field:hover,
.forgot-input-field:focus {
  border-color: #4f46e5;
}

.forgot-submit-button {
  width: 100%;
  padding: 1rem 0;
  background-color: var(--title-color);
  color: var(--container-color);
  border: none;
  border-radius: 5px;
  font-weight: var(--font-medium);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.forgot-submit-button:hover {
  opacity: 0.8;
  transition: all 0.2s ease-out;
}

.forgot-login-redirect {
  text-align: center;
  margin-top: 2rem;
  font-weight: 500;
  color: #555;
}

#forgot-login-link {
  margin-top: 10px;
}

.forgot-login-link {
  color: rgb(25, 115, 206);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-login-link:hover {
  color: #3730a3;
}

@media screen and (max-width: 450px) {
  .forgot-password-form {
    max-width: 450px;
    padding: 2rem;
    padding-top: 3rem;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
