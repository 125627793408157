.dashboadWelcomePage {
  width: 100%;
  max-width: 600px;
  margin: 3rem auto 1rem auto;
  padding: 2rem 0.5rem 2rem 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background-color: #ffffff;
}

.dashboard-header {
  position: relative;
  margin: 3rem auto;
  padding: 1rem;
  max-width: 600px; /* Adjust to match the width of your welcome div */
}

.dashboadWelcomePage {
  /* existing styles... */
}

.hanging-owl {
  position: absolute;
  right: -10;
  top: 50%;
  transform: translateY(-40%);
  max-height: 100px; /* Adjust as needed */
}

.title,
.info,
.welcome {
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;
}

.headingStyle.headingStyle {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: normal;
  padding: 0px;
  margin: 0px 12px 12px 0px;
  color: rgb(31, 38, 44);
}

.logoutButton {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 2rem;
  background-color: rgb(35, 106, 193);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.logoutButton:hover {
  background-color: #0056b3;
}
