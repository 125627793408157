.questions {
  padding: 0 48px;
}

.question-text {
  padding-bottom: 24px;

  font-size: var(--size, 16px);
  letter-spacing: 0.2px;
  line-height: 1.4;
  padding-bottom: 24px;
  font-weight: 300;
  color: rgb(45, 50, 62);
}

.explanation-style {
  margin-left: 15px;
  font-size: 0.9em;
  color: gray;
  border-top: 1px solid rgba(222, 226, 230, 0.5);

  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Add this CSS to your stylesheet */
.reveal-button {
  background-color: #000; /* Green */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
}

.reveal-button:hover {
  background-color: #5b5b5b; /* Darker Green */
}

/* Add this CSS to your stylesheet */
.fancy-text-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none; /* Prevent resizing */
}

.fancy-text-input:focus {
  border-color: #4caf50; /* Green focus color */
  outline: none;
}

/* Add this CSS to your stylesheet */
.checkbox-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.checkbox-label {
  margin-left: 5px;
  font-size: 16px;
}

.checkbox-input {
  margin-right: 5px;
}

.correct-answer-box {
  border: 1px solid #4caf50; /* Green border */
  padding: 8px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #ebf6f0; /* Light green background */
  color: #2e7d32; /* Dark green text */
  font-size: 16px;
}

/* Add this CSS to your stylesheet for Lecture Reference styling */
.lecture-reference {
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid rgba(222, 226, 230, 0.5);
  font-size: 14px; /* Adjust font size as needed */
  color: #2d323e; /* Adjust the color to match your theme */
  font-style: italic; /* Keeps the italic style directly in the CSS */
}

/* Optional: Adjust the padding if you want more space around the text */
.lecture-reference > .MuiTypography-body2 {
  padding: 4px 0;
}
