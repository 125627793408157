.pricing__section {
    padding: 20px 0 160px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricingContainer {
    padding-left: 200px;
    padding-right: 200px;
}

.pricing__container-card {
    background-color: var(--container-color);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
    width: 280px;
    height: 550px;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25rem;
}

    .pricing__container-card:nth-child(2) {
        transform: scale(1.05);
        height: 580px;
        background-color: var(--container-color);
    }

    .pricing__container-card:hover {
        transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

.pricing__heading {
    color: #1c2237;
    text-align: center; /* Centers the heading text */
    margin-bottom: 24px;
}

.pricing__container-card:nth-child(2) {
    margin: 0 24px;
}

.pricing__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing__wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.pricing__heading1 {
    margin-top: 20px;
    font-size: 2.5rem;
    color: black;
    margin-bottom: 0px;
    text-align: center;
}

.pricing__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: rgb(0, 0, 0);
}

    .pricing__container-cardInfo h3 {
        margin-bottom: 5px;
        font-size: 24px;
    }

    .pricing__container-cardInfo h4 {
        font-size: 40px;
    }

    .pricing__container-cardInfo p {
        font-size: 14px;
        margin-bottom: 24px;
    }

.pricing__container-features {
    margin: 16px 0 32px;
    list-style: none;
}

    .pricing__container-features li {
        margin-bottom: 10px;
    }

.pricing__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    margin: 24px 0;
}

.pricing__section {
    padding: 20px 0 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pricing__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allows items to wrap in smaller screens */
}

.pricing__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing__heading {
    color: #1c2237;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-right: 5px;
    width: 100%; /* Full width to center the text across all cards */
}

.pricing__container-card {
    background-color: var(--container-color);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
    width: 280px;
    height: 500px;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25rem;
    margin: 0 12px; /* Adds space between cards */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content inside the card */
}

    .pricing__container-card:hover {
        transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

@media screen and (max-width: 960px) {
    .pricing__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing__container-card {
        width: 90%;
    }

    .pricing__wrapper {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing__container-card:nth-child(2) {
        transform: scale(1);
        background-color: var(--container-color);
        margin: 24px;
    }

    .pricing__container-card:hover {
        transform: none;
    }
}

@media screen and (max-width: 700px) {
}
