.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--body-color);
  margin: 0;
}

.login-form {
  max-width: 450px;
  width: 100%;
  background-color: var(--container-color);
  padding: 4rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
}

.brand-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.brand-content {
  display: flex;
  align-items: center; /* Vertically aligns the contents */
  justify-content: center; /* Horizontally centers the contents */
  text-decoration: none; /* Removes underline from the link */
  color: inherit; /* Ensures the text color is the same as surrounding content */
}

.brand-logo {
  max-height: 70px;
  width: auto;
}

.brand-text {
  vertical-align: middle; /* Ensures the text is vertically centered with the logo */
}

#login {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem; /* Increased spacing for better visual separation */
}

.input-group label {
  font-weight: 500;
  margin-bottom: 0.5rem; /* Label spacing for better visibility */
}

.input-field {
  border: 1px solid #ccc;
  background-color: #ffffff;
  padding: 0.75rem 1rem; /* Increased padding for a larger input area */
  border-radius: 5px; /* Soft rounded corners */
  transition: border-color 0.3s ease; /* Smooth transition on hover */
}

.input-field:hover,
.input-field:focus {
  border-color: #4f46e5; /* Highlight border color on hover & focus */
}

.submit-button {
  width: 100%;
  padding: 1rem 0;
  background-color: var(--title-color);
  color: var(--container-color);
  border: none; /* Removing the default button border */
  border-radius: 5px; /* Soft rounded corners */
  font-weight: var(--font-medium); /* Making the button text slightly bolder */
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
  transition: all 0.2s ease-out;
}

.extras {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.forgot-password-link {
  color: rgb(25, 115, 206);
  font-weight: var(--font-medium);
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #3730a3;
}

.signup-prompt {
  text-align: center;
  margin-top: 2rem; /* Already specified in your .signup-text, but repeating here for clarity */
  font-weight: 500; /* Already specified in your .signup-text, but repeating here for clarity */
  color: #555; /* Darker shade for better readability */
}

.signup-link {
  color: rgb(25, 115, 206); /* Blue color for the Sign up now text */
  font-weight: var(--font-medium); /* Making it slightly bolder */
  text-decoration: none; /* To ensure the link doesn't have underlines */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.signup-link:hover {
  color: #3730a3; /* Darker blue on hover */
}

@media screen and (max-width: 450px) {
  .login-form {
    padding: 2rem;
    padding-top: 3rem;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
