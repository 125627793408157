.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--body-color);
  z-index: 1000;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: var(--title-color);
  font-weight: var(--font-medium);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
  max-height: 50px;
  width: auto;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item:hover {
  border-bottom: 2px solid #000000;
  transition: all 0.9s ease;
}

.nav-links {
  /* height:100% if you want to change it back to this, make sure you get rid of display flex for the parents container*/
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  padding: 0.5rem 1rem;
  transition: 0.3s;
}

.nav-btn {
}

#register-btn {
  margin-right: -20px;
}

.fa-bars {
  color: #0000;
}

.menu-icon {
  display: none;
}

/* Change background header */
.scroll-navbar {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

.brand-name {
  position: relative;
  display: inline-block; /* ensures the container fits the content */
}

.beta-tag {
  font-size: 0.4em; /* smaller font size */
  vertical-align: super; /* aligns to the top */
  margin-left: 4px; /* space between main text and Beta */
  font-weight: normal; /* less bold than the main text */

  border: 1px solid #333333; /* optional: adds a subtle border */
  border-radius: 2px; /* optional: rounds the corners */
  padding: 0 2px; /* optional: adds internal space */
}

@media screen and (max-width: 960px) {
  #register-btn {
    margin-right: 0px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    /* position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%); */
    align-items: center;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: rgb(0, 0, 0);
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

/* For small devices */
@media screen and (max-width: 460px) {
  .container {
    padding: 0 20px;
  }
}
