.nav-button-container,
.submit-button-container {
  width: 100%;
  left: 0;
  right: 0;
  box-sizing: border-box;
}

.inner-container {
  margin: 0px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.submit-button-container {
  border-top: 1px solid rgba(222, 226, 230, 0.5);
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  min-height: 96px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
}

/* Container for navigation buttons */
.nav-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(222, 226, 230, 0.5);
  min-height: 82px;
  margin-bottom: 28px;
  top: 0;
}

.parent-content-container {
  display: flex;
  justify-content: space-between; /* Ensures maximum space between content-container and review-card */
  flex-grow: 1;
  overflow-y: auto;
}

.review-card-container {
  transition: width 0.3s ease;
  overflow-x: hidden;
  padding: 10px;
  overflow-y: auto;
}

.content-container {
  overflow-y: auto; /* Enables vertical scrolling when content overflows */
  flex: 1;
}

.mui-box-root {
  gap: 12px;
  display: flex;
  margin-left: auto;
}

.mui-box-root > :last-child {
  margin-left: auto;
}

.mui-nav-box-root {
  gap: 24px;
  display: flex;
  margin-left: auto;
}

.mui-nav-box-root .MuiIconButton-root {
  border-radius: 50%;
  background-color: #f7f5f5; /* Or any color you want */
  padding: 10px; /* Adjust as necessary */
}

.mui-nav-box-root .MuiSvgIcon-root {
  font-size: 1rem; /* Adjust the size of the icon as necessary */
}

.mui-nav-box-root > :last-child {
  margin-left: auto;
}

.question-counter {
  text-align: center;
  font-weight: 700;
}

/* ✅ Highlight Animation for Submit Button */
.highlight-button {
  animation: pulse 1s infinite alternate;
  border: 2px solid #0015ff !important;
}

/* ✅ Highlight Animation for Navigation Button */
.highlight-nav {
  animation: bounce 1s infinite alternate;
  background-color: #0066ff !important;
  border-radius: 50%;
}

.highlight-nav {
  animation: bounce 1s infinite alternate;
  background-color: #0066ff !important;
  border-radius: 50%;
}

/* ✅ Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

/* ✅ Bounce Animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* ✅ Bounce Animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* ✅ Hint Text */
.hint-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* ✅ Highlight confirm button */
.highlight-confirm {
  background-color: rgb(27, 106, 253) !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(27, 106, 253) !important;
  transition: all 0.3s ease-in-out;
}

/* ✅ Highlight submit button */
.highlight-button {
  animation: pulse 1s infinite alternate;
  border: 2px solid #3c4cff !important;
}
