.who-are-we-section {
    text-align: center;
    padding: 160px 400px;
    position: relative; /* This will help position the blobs absolutely within this section */
    overflow: hidden; /* Prevents blobs from overflowing the section */
}

    .who-are-we-section h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

.WhoAreWeOwlQuestion {
    max-width: 40%;
    height: auto;
}

.blue-text {
    color: rgb(35, 106, 193);
}

.whoweare-navigation {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Adjust as needed */
    margin-bottom: 20px;
}

    .whoweare-navigation button {
        margin: 0 10px; /* Space between buttons */
        cursor: pointer;
        background-color: white;
        font-size: 1.5rem;
        color: black;
        /* Add more styling for the buttons here */
    }

.who-are-we-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
}

@keyframes blob-animate {
    0%, 100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
}

@keyframes blob-animate-alternate {
    0%, 100% {
        border-radius: 50% 50% 40% 60%/40% 60% 30% 70%;
    }

    50% {
        border-radius: 40% 60% 50% 50%/60% 40% 60% 40%;
    }
}

.blob {
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgba(0, 0, 0, 0.8);
    width: 300px;
    height: 300px;
    position: static;
    animation: blob-animate 8s ease-in-out infinite;
}

.blob-second {
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgba(0, 0, 0, 0.8);
    width: 300px;
    height: 300px;
    position: static;
    animation: blob-animate-alternate 8s ease-in-out infinite;
}

.blob,
.blob-second {
    /* ... other styles ... */
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
}

.blob-text {
    /* Adjust padding and text alignment as needed */
    padding: 10px; /* Add some padding */
    text-align: center;
    /* Remove absolute positioning styles */
    color: rgb( 0, 0, 0 ); /* Choose a color that contrasts with your blob background */
    text-align: center;
    width: 80%;
}

.team-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Evenly distribute items with space around */
    gap: 40px; /* Adjust the gap between images */
}

.team-blob {
    /* background: url(placeholder-image.jpg); Replace with each team member's image */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 250px;
    height: 250px;
    animation: blob-animate 8s ease-in-out infinite;
}

.team-blob-second {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 250px;
    height: 250px;
    animation: blob-animate-alternate 8s ease-in-out infinite;
}

.team-blob-svg {
    background-size: contain; /* or a specific size like 200px 200px */
    background-color: #ffffff; /* a contrasting background color */
    border: 8px solid rgb(156, 156, 156); /* optional border for visibility */

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 250px;
    height: 250px;
    animation: blob-animate 8s ease-in-out infinite;
}

.team-name {
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
}

.team-degree,
.team-role {
    display: block; /* Makes each piece of information appear on a new line */
    margin-top: 5px; /* Adjust the spacing as needed */
    color: #333; /* Adjust the color as needed */
    font-size: 0.9em; /* Adjust the font size as needed */
}

.MuiSwitch-root {
    /* Assuming you're using MUI Switch */
    margin: 0px 0px 20px 0px; /* Add margin around the switch */
}

.fade-in {
    animation: fadeIn 0.5s;
}

.fade-out {
    animation: fadeOut 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@media screen and (max-width: 1500px) {
    .who-are-we-section {
        text-align: center;
        padding: 160px 200px;
        position: relative; /* This will help position the blobs absolutely within this section */
        overflow: hidden; /* Prevents blobs from overflowing the section */
    }
}

@media screen and (max-width: 1300px) {
    .who-are-we-section {
        text-align: center;
        padding: 160px 100px;
        position: relative; /* This will help position the blobs absolutely within this section */
        overflow: hidden; /* Prevents blobs from overflowing the section */
    }

    .who-are-we-content {
        display: flex;
        flex-direction: row;
        align-items: center; /* Center align for column layout */
    }

    .WhoAreWeOwlQuestion {
        width: 80%; /* Adjust width for smaller screens */
        margin: 10px 0; /* Add some margin between elements */
    }

    .blob,
    .blob-second {
        width: 30%; /* Adjust width for smaller screens */
        margin: 10px 0; /* Add some margin between elements */
    }
}

@media screen and (max-width: 1000px) {
    .who-are-we-section {
        text-align: center;
        padding: 160px 50px;
        position: relative; /* This will help position the blobs absolutely within this section */
        overflow: hidden; /* Prevents blobs from overflowing the section */
    }
}

@media screen and (max-width: 768px) {
    .who-are-we-content {
        flex-direction: column;
        align-items: center;
    }

    .who-are-we-section {
        text-align: center;
        padding: 160px 0px;
        position: relative; /* This will help position the blobs absolutely within this section */
        overflow: hidden; /* Prevents blobs from overflowing the section */
    }

    .blob,
    .blob-second {
        width: 250px; /* Adjust size for smaller screens */
        height: 250px;
        margin: 10px 0; /* Add some margin between elements */
    }
}
