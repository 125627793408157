.review-card {
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Keeps items centered */
  justify-content: flex-start; /* Change from space-between to flex-start */
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  right: 0;
  transition: all 0.3s ease;
}

.review-card.open {
  width: 280px;
}

.review-card.closed {
  width: 40px;
  overflow-y: hidden;
}

.review-card.closed > *:not(.toggle-button) {
  visibility: hidden;
}

.toggle-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
}
h4 {
  margin-bottom: 20px;
}

.questions-list {
  display: flex;
  /* min-height: 150px; */
  flex-direction: column;
  max-height: calc(100% - 116px);
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.question-status {
  width: 100%;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin-bottom: 10px; /* Optional: space out each question status */
}

.question-number {
  display: inline-block;
  width: 20px; /* Adjust as necessary */
  text-align: left;
}

.question-item {
  width: 100px;
}

.question-status span {
  margin-left: 10px; /* Add some space between the number and the check/x marks */
}

.percentage-display {
  border-top: 1px solid rgba(222, 226, 230, 0.5);
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  min-height: 96px;
  box-sizing: border-box;
  width: 100%;
  position: static; /* Remove this if not needed. Using 'relative' positioning with bottom: 0 might have caused overlaps */
  margin-top: auto;
  background-color: rgb(255, 255, 255);
}

.percentage-value {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.question-item {
  display: flex;
  justify-content: space-between; /* This will push the question number to the left and icons to the right */
  align-items: center; /* Vertically center the content */
  padding: 5px 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  width: 100px; /* Ensures the item stretches to fill the container */
}

.question-item:hover {
  background-color: #e0e0e0; /* Lighter shade for hover */
  border-color: #007bff; /* Highlight color on hover */
}

.question-number {
  margin-right: auto; /* Pushes everything else to the right */
}

.icon-container {
  display: flex;
  gap: 5px; /* Adds space between icons */
}

.review-card-container {
  font-family: "Cabin", sans-serif !important; /* ✅ Force Cabin Font */
  font-size: 16px;
  color: #333; /* Optional: Ensures readability */
}

.review-card-container h2,
.review-card-container p,
.review-card-container span {
  font-family: "Cabin", sans-serif !important; /* ✅ Apply to Headers, Paragraphs, and Spans */
}

/* ✅ Highlight Animation for Review Card Toggle Button */
.highlight-review {
  animation: pulse 1s infinite alternate;
  border: 0.5px solid #000000 !important;
}
