.nav-button-container,
.submit-button-container {
  width: 100%;

  left: 0;
  right: 0;
  box-sizing: border-box;
}

.inner-container {
  margin: 0px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.submit-button-container {
  border-top: 1px solid rgba(222, 226, 230, 0.5);
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  min-height: 96px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
}

/* Container for navigation buttons */
.nav-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(222, 226, 230, 0.5);
  min-height: 82px;
  margin-bottom: 28px;
  top: 0;
}

.parent-content-container {
  display: flex;
  justify-content: space-between; /* Ensures maximum space between content-container and review-card */
  flex-grow: 1;
  overflow-y: auto;
}

.review-card-container {
  transition: width 0.3s ease;
  overflow-x: hidden;
  padding: 10px;
  overflow-y: auto;
}

.content-container-saq {
  overflow-y: auto; /* Enables vertical scrolling when content overflows */
  flex: 1;
  padding: 0 48px;
}

.mui-box-root {
  gap: 12px;
  display: flex;
  margin-left: auto;
}

.mui-box-root > :last-child {
  margin-left: auto;
}

.mui-nav-box-root {
  gap: 24px;
  display: flex;
  margin-left: auto;
}

.mui-nav-box-root .MuiIconButton-root {
  border-radius: 50%;
  background-color: #f7f5f5; /* Or any color you want */
  padding: 10px; /* Adjust as necessary */
}

.mui-nav-box-root .MuiSvgIcon-root {
  font-size: 1rem; /* Adjust the size of the icon as necessary */
}

.mui-nav-box-root > :last-child {
  margin-left: auto;
}

.question-counter {
  text-align: center;
  font-weight: 700;
}

.correct-answer {
  border-color: #4caf50;
  background-color: #e8f5e9;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
}

.part-container {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.background-info-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.reveal-button {
  background-color: #000; /* Green */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
}

.additional-info {
  font-weight: bold;
  font-style: italic; /* Adding italic style */
  margin-top: 10px;
  color: #333;
}

.completed-message-container {
  padding: 10px 48px;

  border-top: 1px solid rgba(222, 226, 230, 0.5);
}
