:root {
  --primary: #000000;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  color: #ffffff;
  background-color: rgb(19, 19, 19);

  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn:hover {
  opacity: 0.8;
  transition: all 0.2s ease-out;
}

.btn--primary {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: var(--small-font-size);
  color: #ffffff;
  background-color: rgb(19, 19, 19);
  border: none;
}

.btn--large {
  padding: 12px 26px;
  font-size: 16px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: var(--small-font-size);
  background-color: transparent;
  color: black;
  padding: 14px 20px;
  border: 1px solid #000000;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 18px;
  color: #ffffff;
  background-color: rgb(28, 120, 201);
  border: none;
}

.btn--large:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #000000;
  color: #fff;
}

.btn--wide:hover {
  opacity: 0.8;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}

.primary {
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}

@media screen and (max-width: 1022px) {
  .btn {
    width: 100%;
  }
}
