.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--body-color);
  margin: 0;
}

.reset-password-form {
  max-width: 450px;
  width: 100%;
  background-color: var(--container-color);
  padding: 4rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
}

.reset-brand-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.reset-brand-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}

.reset-brand-logo {
  margin-right: 10px;
}

.reset-brand-text {
  vertical-align: middle;
}

.reset-input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.reset-input-group label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.reset-input-field {
  border: 1px solid #ccc;
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.reset-input-field:hover,
.reset-input-field:focus {
  border-color: #4f46e5;
}

.reset-submit-button {
  width: 100%;
  padding: 1rem 0;
  background-color: #4f46e5;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.reset-submit-button:hover {
  background-color: #3730a3;
}

.reset-login-redirect {
  text-align: center;
  margin-top: 2rem;
  font-weight: 500;
  color: #555;
}

@media screen and (max-width: 450px) {
  .reset-password-form {
    padding: 2rem;
    padding-top: 3rem;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
