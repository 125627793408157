.confirm-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(239, 238, 237);
}

.confirm-email-content {
  max-width: 400px;
  width: 100%;
  background-color: rgb(245, 243, 242);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirm-email-brand-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
}

.confirm-brand-text {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0rem 0;
  margin-bottom: 2rem;
}
