.dashboard-mock-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #f4f4f9;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
}

.paper {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px; /* Reverted back to keep login & instructions centered */
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
}

.paper-wide {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10%;
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 10;
}

p {
    white-space: pre-line;
}



.mock-test-title {
  font-size: 24px;
  color: black;
  margin-bottom: 20px;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.overlay-content {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.code-input {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button,
.start-exam-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-button:hover,
.start-exam-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.mmx-logo {
  position: absolute;
  top: -3px;
  left: 5px;
  max-width: 100px;
  height: auto;
}

.mock-test-title {
  margin-top: 30px;
}

/* Full-width practice interface */
.practice-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white; /* Ensure background consistency */
}


.instructions-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    text-align: left; /* Align text to the left for better readability */
}

.instructions-title {
    font-size: 24px;
    color: black;
    margin-bottom: 20px;
    text-align: center; /* Center the title */
}

.instructions-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

    .instructions-list li {
        margin-bottom: 10px;
    }

.start-exam-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 20px auto 0; /* Center the button */
}

    .start-exam-button:hover {
        background-color: #0056b3;
    }